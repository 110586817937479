import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import {
  Events,
  Event,
  Homepage,
  Page,
  // Races,
  Race,
  News,
  Post,
  Search,
  SiteProvider,
  SubHeader,
  DefaultFooter,
  DefaultHeader,
} from "@walltowall/p3r-races"
import {
  primaryBlack,
  primaryRed,
  primaryBlue,
  darkerBlue,
  button,
  newsEvents,
} from "./colors"
import Error404 from "./components/Error404"
import moveM from "./images/move_m.svg"
import "swiper/css/swiper.css"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import newsEventsBackgroundFallback from "./images/LibertyMile_Blog_default.svg"
import queryString from "query-string"

function App() {
  const [menus, setMenus] = useState(null)
  const root =
    process.env.NODE_ENV === "development"
      ? "http://localhost:4242"
      : "https://p3r.org"

  useEffect(() => {
    const menuParams = queryString.stringify({
      main: "Liberty Mile Main Menu",
      sub: "Liberty Mile Sub Menu",
      footer: "Liberty Mile Footer",
      footer_secondary: "Liberty Mile Footer Secondary",
    })

    fetch(`${root}/sites/site_menus.json?${menuParams}`)
      .then((response) => response.json())
      .then((data) => {
        setMenus(data.menus)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <SiteProvider root={root}>
        {menus && (
          <>
            <SubHeader menus={menus} />

            {/* TODO: create a default header in p3r-races */}
            <DefaultHeader
              site={"fleet-feet-liberty-mile"}
              logoAlt="Fleet Feet Liberty Mile"
              mediumLogoProperties={{
                mWidth: "95px",
                mHeight: "108px",
                mLeft: "18px",
              }}
              largeLogoProperties={{
                lWidth: "110px",
                lLeft: "21px",
              }}
              menus={menus}
              menuHover="rgba(0,0,0,0.7)"
              background={primaryRed}
              registerBackground={primaryBlue}
              navHoverBorderBottom="white"
              searchBackground={primaryBlack}
              primaryText="white"
            />
          </>
        )}

        <Switch>
          <Route exact path={["/", "/pages/home-fleet-feet-liberty-mile"]}>
            <Homepage
              site="fleet-feet-liberty-mile"
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlue,
                secondary: primaryRed,
                tertiary: primaryBlue,

                countdownBackground: primaryBlue,

                menuBackground: primaryBlack,

                primaryText: "white",

                cardsBackground: primaryBlue,
                cardTitle: primaryBlue,

                promoBannersBackground: darkerBlue,

                link: "black",
                linkUnderline: primaryBlue,

                racesBackground: primaryBlack,
                racesCardBackground: primaryBlue,

                racePromoBackground: primaryBlack,

                button,
                newsEvents,
              }}
            />
          </Route>

          <Route exact path="/events">
            <Events site="fleet-feet-liberty-mile" color={primaryRed} />
          </Route>

          <Route path="/events/:id">
            <Event
              site="fleet-feet-liberty-mile"
              colors={{
                primary: primaryBlack,
                secondary: primaryBlue,
                button,
              }}
              fallback={moveM}
            />
          </Route>

          {/* TODO: will this be a thing */}
          {/* <Route exact path='/races'>
            <Races
              pageId='races-pittsburgh-marathon'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: red,
                button,
              }}
            />
          </Route> */}

          <Route path="/pages/:id">
            <Page
              site="fleet-feet-liberty-mile"
              colors={{
                primary: primaryBlack,
                secondary: primaryBlue,
                tertiary: primaryRed,
                primaryText: "white",
                linkUnderline: primaryBlack,
                linkBlockBackground: primaryBlue,
                accordionBackground: primaryBlue,
                twoColumnBackground: primaryBlue,
                cardsBackground: primaryBlue,
                button,
              }}
              mapboxToken="pk.eyJ1IjoiYnJvd250b3duNDEyIiwiYSI6ImNpamRheHZyejAwNjJ2aG0wa2JyM3U4bW0ifQ.etQxVe143jcNlm9ocMBA6w"
            />
          </Route>

          <Route path="/races/:id">
            <Race
              site="fleet-feet-liberty-mile"
              colors={{
                primary: primaryBlack,
                secondary: primaryBlack,
                tertiary: primaryRed,
                primaryText: "white",
                accordionBackground: primaryBlack,
                button,
              }}
            />
          </Route>

          <Route exact path="/blog">
            <News
              site="fleet-feet-liberty-mile"
              type="blog"
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlack,
                secondary: primaryBlue,
                tertiary: primaryRed,
                primaryText: "white",
                button,
              }}
            />
          </Route>

          <Route exact path="/news">
            <News
              site="fleet-feet-liberty-mile"
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              type="news"
              colors={{
                primary: primaryBlack,
                secondary: primaryBlue,
                tertiary: primaryRed,
                primaryText: "white",
                button,
              }}
            />
          </Route>

          <Route path="/blog/:id">
            <Post
              site="fleet-feet-liberty-mile"
              type="blog"
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlack,
                secondary: primaryBlue,
                tertiary: primaryRed,
                button,
              }}
            />
          </Route>

          <Route path="/news/:id">
            <Post
              site="fleet-feet-liberty-mile"
              type="news"
              newsEventsBackgroundFallback={newsEventsBackgroundFallback}
              colors={{
                primary: primaryBlack,
                secondary: primaryBlue,
                tertiary: primaryRed,
                button,
              }}
            />
          </Route>

          <Route path="/search">
            <Search
              site="fleet-feet-liberty-mile"
              colors={{
                primary: primaryBlack,
                secondary: primaryBlue,
                primaryText: "white",
              }}
            />
          </Route>

          <Route path="/404">
            <Error404 />
          </Route>

          <Route>
            <Redirect to="/404" />
          </Route>
        </Switch>

        {/* TODO: create a default footer in p3r-races */}
        <DefaultFooter
          menus={menus}
          logoAlt="Fleet Feet Liberty Mile"
          primaryText="white"
          background={primaryRed}
          social={{
            facebook: "https://www.facebook.com/P3RmovesPGH",
            twitter: "https://twitter.com/P3RmovesPGH",
            instagram: "https://instagram.com/P3RmovesPGH",
          }}
        />
      </SiteProvider>
    </Router>
  )
}

export default App
