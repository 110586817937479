import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import { red } from '../colors'
import banana from '../images/404_image.png'

const Error404Container = styled.main`
  background-color: #209ed8;
  padding: 12px 21px 48px;
  text-align: center;

  @media screen and (min-width: 500px) {
    padding: 12px 8.3vw 48px;
  }

  h1 {
    color: white;
    font-size: 163px;
    font-size: 10.1875rem;
    margin-bottom: 0;

    @media screen and (min-width: 600px) {
      font-size: 245px;
      font-size: 15.3125rem;
    }
  }

  img {
    width: 222px;
    margin: -46px 0 12px;

    @media screen and (min-width: 600px) {
      width: 318px;
      margin: -62px 0 12px;
    }
  }

  p {
    color: white;
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 800;
    max-width: 723px;
    margin: 0 auto 36px;

    @media screen and (min-width: 600px) {
      font-size: 38px;
      font-size: 2.375rem;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    font-size: 1.25rem;
    transition: opacity 0.21s ease-out;
    font-weight: 800;
    border: 2px solid white;
    border-radius: 4px;
    padding: 10px 38px 12px;

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }
`

const Error404 = () => {
  return (
    <Error404Container>
      <h1>404</h1>
      <img src={banana} alt="banana peel" />

      <p>Uh Oh! It looks like that page you’re looking for does not exist. Return home or try searching instead.</p>

      <Link to="/">
        Go to Home
      </Link>
    </Error404Container>
  )
}

export default Error404
